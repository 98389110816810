const ShopTypes = {
  FETCH_COLLECTIONS_START: `FETCH_COLLECTIONS_START`,
  FETCH_COLLECTIONS_SUCCESS: `FETCH_COLLECTIONS_SUCCESS`,
  FETCH_COLLECTIONS_FAILURE: `FETCH_COLLECTIONS_FAILURE`,
  FETCH_PRODUCTS_START: `FETCH_PRODUCTS_START`,
  FETCH_PRODUCTS_SUCCESS: `FETCH_PRODUCTS_SUCCESS`,
  FETCH_PRODUCTS_FAILURE: `FETCH_PRODUCTS_FAILURE`
};

export default ShopTypes;
